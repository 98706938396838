// import node module libraries
import React, { Fragment, useMemo, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useParams } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { deleteBookFromPackAction } from "actions/packs";
import { Eye, MoreVertical, Trash } from "react-feather";
import SpinnerLoading from "components/dashboard/SpinnerLoading";

const PackTable = ({ books }) => {
  const { loading, user } = useSelector((state) => state.auth);

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const { id: pack_id } = useParams();
  const handleDeleteBook = (id) => {
    setBookToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteBook = () => {
    if (bookToDelete && confirmationMessage === "DELETE BOOK") {
      dispatch(deleteBookFromPackAction(bookToDelete));
      setBookToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));
  const ActionMenu = ({ id }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <Link
              to={`/books-management/session?book_id=${id}&pack_id=${pack_id}`}
            >
              <Eye size="15px" className="dropdown-item-icon" /> Sessions
            </Link>
          </Dropdown.Item>
          {user?.role == "admin" && user?.school == "IRead" && (
            <Dropdown.Item eventKey="2" onClick={() => handleDeleteBook(id)}>
              {" "}
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeleteBook}
              />
              Remove
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "Book ID", show: false },
      {
        accessor: "title",
        Header: "Book Title",
        Cell: ({ value, row }) => {
          return (
            <div className="d-lg-flex align-items-center">
              <div>
                <Link
                  to={`/books-management/session?book_id=${row.original.id}&pack_id=${pack_id}`}
                >
                  <Image
                    src={row.original.img}
                    alt=""
                    className="img-4by3-lg rounded"
                  />
                </Link>
              </div>
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <Link
                  to={`/books-management/session?book_id=${row.original.id}&pack_id=${pack_id}`}
                >
                  <h4 className="text-inherit">{value}</h4>
                </Link>
              </div>
            </div>
          );
        },
      },
      { accessor: "author", Header: "The Author", show: true },
      { accessor: "category", Header: "Category", show: true },
      { accessor: "page_number", Header: "Page Number", show: true },

      {
        accessor: "action",
        Header: "",
        Cell: ({ row }) => {
          return <ActionMenu id={row.original.id} />;
        },
      },
    ],
    []
  );

  const data = useMemo(() => books, [books]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Book"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive overflow-y-hidden">
        {loading ? (
          <SpinnerLoading />
        ) : (
          <Table
            hover
            {...getTableProps()}
            className="text-nowrap table-centered"
          >
            <thead className="table-light">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this book from this pack?</p>
          <h4 className="text-secondary">
            you must write "DELETE BOOK" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant={
              confirmationMessage !== "DELETE BOOK" ? "secondary" : "danger"
            }
            disabled={confirmationMessage !== "DELETE BOOK"}
            onClick={confirmDeleteBook}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default PackTable;
