import { enc, HmacSHA256 } from "crypto-js";
/**
 * Functions in utils
 */

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x, decimal = 0) => {
  return x.toLocaleString("en-US", { minimumFractionDigits: decimal });
};

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
  let color = "";
  switch (itemstatus) {
    case "Partial":
      color = "info";
      break;
    case "Pending":
      color = "warning";
      break;
    case "paid":
      color = "success";
      break;
    case "unpaid":
      color = "danger";
      break;
    default:
      color = "primary";
  }
  return color;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
  let color = "";
  switch (category) {
    case "Saas Services":
    case "Entertainment":
    case "Extra":
      color = "info";
      break;
    case "Design":
      color = "warning";
      break;
    case "Marketing":
      color = "success";
      break;
    case "Development":
      color = "danger";
      break;
    case "SEO":
      color = "primary";
      break;
    default:
      color = "primary";
  }
  return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
  var hours = date.getHours();
  var day = date.getUTCDate();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();

  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime =
    day + "/" + month + "/" + year + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};

//function form reform date and time
export const reformatISODate = (isoDate) => {
  const date = new Date(isoDate);
  date.setHours(date.getHours() - 1);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy} ${hh}h:${min}`;
};
export const reformatSearchDate = (isoDate) => {
  const date = new Date(isoDate);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy}`;
};
export const reformatDate = (originalDate) => {
  const parts = originalDate.split('/');
  const dd = parts[0];
  const mm = parts[1];
  const yyyy = parts[2];

  return `${dd}-${mm}-${yyyy}`;
};

// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yyyy = date.getFullYear();
  let mm = date.getMonth();
  let dd = date.getDate();
  var today = month[mm] + " " + dd + ", " + yyyy;
  return today;
};
// function to  remove Duplicates

export function removeDuplicates(arr) {
  // Use a Set to keep track of unique combinations of teacher_name and teacher_color
  const uniqueSet = new Set();

  // Filter the array to include only unique objects
  const uniqueArr = arr.filter((obj) => {
    const key = `${obj.teacher_name}_${obj.teacher_color}`;

    // If the key is not in the set, add it and return true to keep the object
    if (!uniqueSet.has(key)) {
      uniqueSet.add(key);
      return true;
    }

    // If the key is already in the set, return false to exclude the object
    return false;
  });

  return uniqueArr;
}

// function to generate slug or ID with slug format
export const getSlug = (text) => {
  text = text.toLowerCase();
  text = text.replace(/ /g, "-").replace(/[^\w-]+/g, "");
  return text;
};
// get public id
const regex = /\/v\d+\/([^/]+)\.\w{3,4}$/;
export const getPublicIdFromUrl = (url) => {
  const match = url.match(regex);
  return match ? match[1] : null;
};

const CLOUDINARY_API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
export const generateCloudinarySignature = (params) => {
  const signaturePayload = Object.keys(params)
    .sort()
    .map((key) => key + "=" + params[key])
    .join("&");

  const signature = HmacSHA256(
    signaturePayload,
    CLOUDINARY_API_SECRET
  ).toString(enc.Hex);

  return signature;
};
export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + " M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + " K";
  } else {
    return num?.toString();
  }
};

export const checkOrder = (data) => {
  let ascending = false;
  let descending = false;
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth();
  var percentageCorrectOrder = 0;
  var orderType = "down";

  if (data) {
    if (data[currentMonth] > data[currentMonth - 1]) {
      ascending = true;
      percentageCorrectOrder = (
        ((data[currentMonth] - data[currentMonth - 1]) / data[currentMonth]) *
        100
      ).toFixed(2);
      orderType = "up";
    } else if (data[currentMonth] < data[currentMonth - 1]) {
      percentageCorrectOrder = (
        ((data[currentMonth] - data[currentMonth - 1]) /
          data[currentMonth - 1]) *
        100
      ).toFixed(2);
      descending = true;
    }
  }

  return {
    orderType: orderType,
    percentage: percentageCorrectOrder,
  };
};

export function formatTime(seconds) {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // Build the formatted time string
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += hours + "h:";
  }

  if (minutes > 0 || hours > 0) {
    formattedTime += minutes + "m";
  }

  if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
    if (hours > 0 || minutes > 0) {
      formattedTime += ":";
    }
    formattedTime += remainingSeconds + "s";
  }

  return formattedTime;
}
export const generateRandomId = () => {
  const RandomNumber = Math.floor(100000 + Math.random() * 900000);
  return RandomNumber;
};

export function generateRandomColors(numberOfColors) {
  const colors = [];

  for (let i = 0; i < numberOfColors; i++) {
    // Generate random RGB values
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Create CSS color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    // Add the color to the array
    colors.push(color);
  }

  return colors;
}
export function generateRandomKey() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < 16; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomString;
}
const utils = [
  numberWithCommas,
  getFileExtension,
  getRandomNo,
  getStatusColor,
  chunk,
  getTimeValue,
  getDateValue,
  getSlug,
];

export default utils;
