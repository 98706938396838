// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import sub components
import ApprovedSession from "./ApprovedSession";
import NotApprovedSession from "./NotApprovedSession";
import AddFollowSession from "./AddFollowSession";

const SessionSubPage = () => {
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="notapproved">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Sessions Subscription</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                    Sessions Subscription
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Readers</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}
            </div>
            <Col className="d-flex justify-content-end">
              <div style={{ marginBottom: "20px" }}>
                <AddFollowSession show={show} setShow={setShow} />
              </div>
            </Col>
          </Col>
        </Row>
        <Card.Header className="border-bottom-0 p-0 bg-white">
          <Nav className="nav-lb-tab">
            <Nav.Item>
              <Nav.Link eventKey="notapproved" className="mb-sm-3 mb-md-0">
                Sessions Awaiting Approval
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                Approved Sessions
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											List of User
										</Nav.Link>
									</Nav.Item> */}
          </Nav>
        </Card.Header>
        <Card.Body className="p-0">
          <Tab.Content>
            <Tab.Pane eventKey="approved" className="pb-4">
              <ApprovedSession />
            </Tab.Pane>
            <Tab.Pane eventKey="notapproved" className="pb-4">
              <NotApprovedSession />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="pending" className="pb-4">
										
									</Tab.Pane> */}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};

export default SessionSubPage;
