// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Col,
  Row,
  Card,
  Breadcrumb,
  Button,
  Form,
  Badge,
} from "react-bootstrap";
import { Plus } from "react-feather";

// import custom components
import { useDispatch, useSelector } from "react-redux";
import {
  addQuiz,
  createQuizAction,
  fetchOneQuizAction,
  updateQuizAction,
} from "actions/quiz";

import { ToastContainer } from "react-toastify";
import { generateRandomId } from "helper/utils";
import { Trash3 } from "react-bootstrap-icons";

import { Typeahead } from "react-bootstrap-typeahead";
// import { getPointsAction } from "actions/curriculum";
import AutocompleteCategory from "./AutoCompliteCategory";

// import data files

const CreateQuiz = () => {
  // const { points, taskPoints } = useSelector((state) => state.curriculum);
  const { loading } = useSelector((state) => state.auth);
  const { quiz } = useSelector((state) => state.quizs);

  const { quizEdited } = useSelector((state) => state.quizs);
  const [quizId, setQuizId] = useState();
  const [selectedType, setSelectedType] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const quiz_id = searchParams.get("quiz_id");
  useEffect(() => {
    if (quiz_id != null && quiz_id !== undefined) {
      setQuizId(quiz_id);
    }
  }, [quiz_id]);
  const handleOnchange = (data) => {
    dispatch(addQuiz(data));
  };

  const handleDeleteChoice = (questionId, choiceId) => {
    handleOnchange({
      ...quiz,
      questions: quiz.questions.map((element) => {
        if (element._id === questionId) {
          return {
            ...element,
            data: element.data.filter((choice) => choice._id !== choiceId),
          };
        }
        return element;
      }),
    });
  };
  const handleDeleteQuestion = (questionId) => {
    const updatedQuestions = quiz.questions.filter((q) => q._id !== questionId);

    dispatch(addQuiz({ ...quiz, questions: updatedQuestions }));
  };
  const handleCreateQuiz = async (e) => {
    if (quizId) {
      dispatch(updateQuizAction(quiz, setQuizId, navigate));
    } else {
      dispatch(createQuizAction(quiz, navigate));
    }
    e.preventDefault();
  };
  useEffect(() => {
    if (quizId) {
      dispatch(fetchOneQuizAction(quizId));
    }
  }, [quizId]);
  useEffect(() => {
    if (quizEdited?._id) {
      const editedQuiz = quizEdited;

      const updatedQuiz = {
        ...quiz,
        _id: editedQuiz?._id,
        title: editedQuiz?.title,
        desc: editedQuiz?.desc,
        pass_mark: editedQuiz?.pass_mark,
        duration: editedQuiz?.duration,
        questions: editedQuiz?.questions?.map((question) => ({
          type: question?.type,
          _id: question?._id,
          title: question?.title,
          desc: question?.desc,
          category: question?.category?.name,
          mark: question?.mark,
          data: question?.data,
          covredPoints: question?.covredPoints,
        })),
      };

      handleOnchange(updatedQuiz);
    }
  }, [quizEdited]);
  const [numberOfCorrectValues, setNumberOfCorrectValues] = useState(2);
  const [numberOfCorrect, setNumberOfCorrect] = useState(2);

  const handleAddNewRow = () => {
    setNumberOfCorrectValues((prev) => prev + 1);
  };
  const handleAddRow = () => {
    setNumberOfCorrect((prev) => prev + 1);
  };
  const [selectedPoint, setSlectedPoint] = useState(null);
  const handleSelectionPoints = (selected) => {
    if (selected.length > 0) {
      setSlectedPoint({
        _id: selected[0]._id,
        title: selected[0].title,
      });
    }
  };

  // CovredPoints
  // useEffect(() => {
  //   dispatch(getPointsAction());
  // }, []);
  // const handleAddPoint = (id) => {
  //   if (selectedPoint) {
  //     handleOnchange({
  //       ...quiz,
  //       questions: quiz.questions.map((element) => {
  //         if (element._id === id) {
  //           return {
  //             ...element,
  //             covredPoints: element.covredPoints
  //               ? [...element.covredPoints, selectedPoint]
  //               : [selectedPoint],
  //           };
  //         }
  //         return element;
  //       }),
  //     });
  //   }
  // };
  return (
    <Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Quizes</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Quiz mangment</Breadcrumb.Item>
                <Breadcrumb.Item active>Quizes</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-3">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Quiz title.."
                    id="title"
                    required
                    value={quiz?.title}
                    onChange={(e) =>
                      handleOnchange({ ...quiz, title: e.target.value })
                    }
                  />
                  {/* <Form.Text className="text-muted">
                    Field must contain a unique value
                  </Form.Text> */}
                </Form.Group>
                <Form.Group className="mb-2 event-date">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description..."
                    id="desc"
                    required
                    value={quiz?.desc}
                    onChange={(e) =>
                      handleOnchange({ ...quiz, desc: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2 event-date">
                  <Form.Label>Pass Mark (%) </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Pass mark (%)"
                    id="pass_mark"
                    required
                    value={quiz?.pass_mark}
                    onChange={(e) =>
                      handleOnchange({ ...quiz, pass_mark: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2 event-date">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="duration"
                    id="duration"
                    required
                    value={quiz?.duration}
                    onChange={(e) =>
                      handleOnchange({ ...quiz, duration: e.target.value })
                    }
                  />
                </Form.Group>
                <hr
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                {quiz?.questions?.map((q, key) => (
                  <Fragment key={key}>
                    <span
                      style={{ marginLeft: "75%", color: "red" }}
                      onClick={() => handleDeleteQuestion(q._id)}
                    >
                      <Trash3
                        size="15px"
                        className="dropdown-item-icon"
                        color="red"
                      />
                      Delete Question
                    </span>
                    <h3>Question {key + 1}</h3>
                    {/* Title */}
                    <Form.Group className="mb-3" style={{ width: "70%" }}>
                      <Form.Label>Question Title </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Question title.."
                        id="q_title"
                        required
                        value={q?.title}
                        onChange={(e) =>
                          handleOnchange({
                            ...quiz,
                            questions: quiz.questions?.map((element) => {
                              if (element._id === q._id) {
                                return { ...element, title: e.target.value };
                              }
                              return element;
                            }),
                          })
                        }
                      />
                    </Form.Group>
                    {/* Description */}
                    <Form.Group className="mb-3" style={{ width: "70%" }}>
                      <Form.Label>Question Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        placeholder="Question description.."
                        id="q_desc"
                        required
                        value={q?.desc}
                        onChange={(e) =>
                          handleOnchange({
                            ...quiz,
                            questions: quiz.questions.map((element) => {
                              if (element._id === q._id) {
                                return { ...element, desc: e.target.value };
                              }
                              return element;
                            }),
                          })
                        }
                      />
                    </Form.Group>
                    {/* Category */}
                    <Form.Group className="mb-3" style={{ width: "70%" }}>
                      <Form.Label>Question Category</Form.Label>
                      <div className="d-flex align-items-center mb-2">
                        <div className="flex-grow-1">
                          <AutocompleteCategory q={q} />
                        </div>

                        {/* <div className="ms-2" style={{ marginLeft: "20%" }}>
                          <Plus size="18px" className="dropdown-item-icon" /> Add Category
                        </div> */}
                      </div>
                    </Form.Group>

                    {/* <Form.Group className="mb-3" style={{ width: "40%" }}>
                      <div className="flex-grow-1">
                        <Form.Label>Covred Points :</Form.Label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typeahead
                            id="points-typeahead"
                            labelKey="title"
                            options={points}
                            placeholder="Choose a point"
                            onChange={handleSelectionPoints}
                          />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAddPoint(q?._id);
                            }}
                          >
                            <Plus size="18px" className="dropdown-item-icon" />{" "}
                            Add Point
                          </span>
                        </div>
                      </div>
                    </Form.Group> */}
                    {/* <div
                      style={{ padding: "20px", width: "70%", display: "flex" }}
                    >
                      {q?.covredPoints?.map((p) => (
                        <Badge
                          key={p?._id}
                          style={{
                            marginTop: 10,
                            display: "flex",
                            width: "fit-content",
                            marginRight: "20px",
                          }}
                          bg="secondary"
                        >
                          {p?.title}
                          <div
                            style={{ cursor: "pointer", marginLeft: 2 }}
                            onClick={() => handleDeletePoint(p?._id)}
                          >
                            <i className="fe fe-x fs-6"></i>
                          </div>
                        </Badge>
                      ))}
                    </div> */}
                    {/* Mark */}
                    <Form.Group className="mb-3" style={{ width: "70%" }}>
                      <Form.Label>Question Mark</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Question mark.."
                        id="q_mark"
                        required
                        value={q?.mark}
                        onChange={(e) =>
                          handleOnchange({
                            ...quiz,
                            questions: quiz.questions.map((element) => {
                              if (element._id === q._id) {
                                return { ...element, mark: e.target.value };
                              }
                              return element;
                            }),
                          })
                        }
                      />
                    </Form.Group>
                    {/* Answer */}

                    {/* TRUE FALSE QUESTION */}

                    {q.type === "true-false" && (
                      <Form.Group className="mb-3" style={{ width: "70%" }}>
                        <Form.Label>TF Question answer</Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            id={`q_answer_true_${q._id}`}
                            label="True"
                            checked={q.data.correct === "true"}
                            onChange={(e) =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: { ...q.data, correct: "true" },
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          />
                          <Form.Check
                            type="radio"
                            id={`q_answer_false_${q._id}`}
                            label="False"
                            checked={q.data.correct === "false"}
                            onChange={(e) =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: { ...q.data, correct: "false" },
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          />
                        </div>
                      </Form.Group>
                    )}

                    {/* MULTI CHOICE QUESTION */}

                    {q.type === "multi-choice" && (
                      <>
                        <Form.Group className="mb-3" style={{ width: "87%" }}>
                          <Form.Label>MC Question Answers</Form.Label>
                          {Array.isArray(q.data) &&
                            q.data.map((choice, choiceIndex) => (
                              <div
                                key={choice._id}
                                className="d-flex align-items-center mb-2"
                              >
                                <div className="flex-grow-1">
                                  <Form.Control
                                    type="text"
                                    placeholder={`Choice ${choiceIndex + 1}`}
                                    value={choice?.content}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        content: e.target.value,
                                                      }
                                                    : c
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className="ms-2"
                                  style={{ marginTop: "7px" }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    id={`q_multi_choice_${q._id}_${choice._id}`}
                                    label=""
                                    checked={choice.correct === "true"}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        correct: e.target
                                                          .checked
                                                          ? "true"
                                                          : "false",
                                                      }
                                                    : c
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    marginLeft: "15%",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handleDeleteChoice(q._id, choice._id)
                                  }
                                >
                                  <Trash3
                                    size="15px"
                                    className="dropdown-item-icon"
                                    color="red"
                                  />
                                  Delete Choice
                                </div>
                              </div>
                            ))}
                          <span
                            onClick={() =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: [
                                        ...q.data,
                                        {
                                          _id: generateRandomId(),
                                          content: "",
                                          correct: "false",
                                        },
                                      ],
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          >
                            <Plus size="18px" className="dropdown-item-icon" />{" "}
                            Add another Answer
                          </span>
                        </Form.Group>
                      </>
                    )}

                    {/* CHECKBOX QUESTION */}

                    {q.type === "checkbox" && (
                      <>
                        <Form.Group className="mb-3" style={{ width: "87%" }}>
                          <Form.Label>CheckBox Question Answers</Form.Label>
                          {Array.isArray(q.data) &&
                            q.data.map((choice, choiceIndex) => (
                              <div
                                key={choice._id}
                                className="d-flex align-items-center mb-2"
                              >
                                <div className="flex-grow-1">
                                  <Form.Control
                                    type="text"
                                    placeholder={`Choice ${choiceIndex + 1}`}
                                    value={choice?.content}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        content: e.target.value,
                                                      }
                                                    : c
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className="ms-2"
                                  style={{ marginTop: "7px" }}
                                >
                                  <Form.Check
                                    type="radio"
                                    name={`q_multi_choice_${q._id}`}
                                    id={`q_multi_choice_${q._id}_${choice._id}`}
                                    label=""
                                    checked={choice.correct === "true"}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        correct: e.target
                                                          .checked
                                                          ? "true"
                                                          : "false",
                                                      }
                                                    : { ...c, correct: "false" }
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    marginLeft: "15%",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handleDeleteChoice(q._id, choice._id)
                                  }
                                >
                                  Delete Choice
                                </div>
                              </div>
                            ))}

                          <span
                            onClick={() =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: [
                                        ...q.data,
                                        {
                                          _id: generateRandomId(),
                                          content: "",
                                          correct: "false",
                                        },
                                      ],
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          >
                            Add another Answer
                          </span>
                        </Form.Group>
                      </>
                    )}

                    {/* LINEAR SCALE QUESTION */}
                    {q.type === "linear-scale" && (
                      <>
                        <Form.Group className="mb-3" style={{ width: "70%" }}>
                          <Form.Label>Linear Scale Question</Form.Label>
                          <Row>
                            <Col>
                              <Form.Label>Start Value</Form.Label>
                              <Form.Select
                                style={{ width: "30%" }}
                                value={q.data.startValue || "1"}
                                onChange={(e) =>
                                  handleOnchange({
                                    ...quiz,
                                    questions: quiz.questions.map((element) => {
                                      if (element._id === q._id) {
                                        return {
                                          ...element,
                                          data: {
                                            ...element.data,
                                            startValue: e.target.value,
                                          },
                                        };
                                      }
                                      return element;
                                    }),
                                  })
                                }
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                              </Form.Select>
                            </Col>
                            <Col style={{ width: "30%", marginLeft: "-50%" }}>
                              <Form.Label>End Value</Form.Label>
                              <Form.Select
                                style={{ width: "30%" }}
                                value={q.data.endValue || "5"}
                                onChange={(e) =>
                                  handleOnchange({
                                    ...quiz,
                                    questions: quiz.questions.map((element) => {
                                      if (element._id === q._id) {
                                        return {
                                          ...element,
                                          data: {
                                            ...element.data,
                                            endValue: e.target.value,
                                          },
                                        };
                                      }
                                      return element;
                                    }),
                                  })
                                }
                              >
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                              </Form.Select>
                            </Col>
                          </Row>

                          <Row>
                            <Form.Group>
                              <Form.Label></Form.Label>
                              <Col className="d-flex align-items-center">
                                <h5 style={{ paddingRight: "1%" }}>
                                  {q.data.startValue} :{" "}
                                </h5>
                                <Form.Control
                                  style={{ width: "30%" }}
                                  type="text"
                                  placeholder="Label"
                                  value={q.data.labe1}
                                  onChange={(e) =>
                                    handleOnchange({
                                      ...quiz,
                                      questions: quiz.questions.map(
                                        (element) => {
                                          if (element._id === q._id) {
                                            return {
                                              ...element,
                                              data: {
                                                ...element.data,
                                                label1: e.target.value,
                                              },
                                            };
                                          }
                                          return element;
                                        }
                                      ),
                                    })
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group>
                              <Form.Label></Form.Label>
                              <Col className="d-flex align-items-center">
                                <h5 style={{ paddingRight: "1%" }}>
                                  {q.data.endValue} :{" "}
                                </h5>
                                <Form.Control
                                  style={{ width: "30%" }}
                                  placeholder="Label"
                                  type="text"
                                  value={q.data.label2}
                                  onChange={(e) =>
                                    handleOnchange({
                                      ...quiz,
                                      questions: quiz.questions.map(
                                        (element) => {
                                          if (element._id === q._id) {
                                            return {
                                              ...element,
                                              data: {
                                                ...element.data,
                                                label2: e.target.value,
                                              },
                                            };
                                          }
                                          return element;
                                        }
                                      ),
                                    })
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Row>
                        </Form.Group>
                      </>
                    )}

                    {selectedType === "tick-box-grid" && (
                      <>
                        <Form.Group className="mb-3" style={{ width: "87%" }}>
                          <Form.Label>Tick Box grid</Form.Label>
                          <span>
                            <div
                              style={{
                                cursor: "pointer",
                                marginLeft: "91%",
                                border: "1px solid #58A55C",
                                color: "white",
                                backgroundColor: "#58A55C",
                              }}
                              onClick={handleAddNewRow}
                            >
                              <Plus
                                color="white"
                                size="18px"
                                className="dropdown-item-icon"
                              />{" "}
                              Add row
                            </div>
                          </span>
                          {Array.isArray(q.data) &&
                            q.data.map((choice, choiceIndex) => (
                              <div
                                key={choice._id}
                                className="d-flex align-items-center mb-2"
                              >
                                <div className="flex-grow-1">
                                  <Form.Control
                                    type="text"
                                    placeholder={`Choice ${choiceIndex + 1}`}
                                    value={choice?.content}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        content: e.target.value,
                                                      }
                                                    : c
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>

                                <div
                                  className="ms-2"
                                  style={{ marginTop: "7px", display: "flex" }}
                                >
                                  {[...Array(numberOfCorrectValues).keys()].map(
                                    (correctIndex) => (
                                      <Form.Check
                                        style={{ marginLeft: "15px" }}
                                        key={correctIndex}
                                        type="checkbox"
                                        id={`q_multi_choice_${q._id}_${choice._id}_${correctIndex}`}
                                        label=""
                                        checked={
                                          choice[`correct${correctIndex}`] ===
                                          "true"
                                        }
                                        onChange={(e) =>
                                          handleOnchange({
                                            ...quiz,
                                            questions: quiz.questions.map(
                                              (element) => {
                                                if (element._id === q._id) {
                                                  return {
                                                    ...q,
                                                    data: q.data.map(
                                                      (c, index) =>
                                                        index === choiceIndex
                                                          ? {
                                                              ...c,
                                                              [`correct${correctIndex}`]:
                                                                e.target.checked
                                                                  ? "true"
                                                                  : "false",
                                                            }
                                                          : c
                                                    ),
                                                  };
                                                }
                                                return element;
                                              }
                                            ),
                                          })
                                        }
                                      />
                                    )
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "15%",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handleDeleteChoice(q._id, choice._id)
                                  }
                                >
                                  <Trash3
                                    size="15px"
                                    className="dropdown-item-icon"
                                    color="red"
                                  />
                                  Delete Choice
                                </div>
                              </div>
                            ))}
                          {[...Array(numberOfCorrectValues).keys()].map(
                            (correctIndex) => (
                              <div
                                key={`Row${correctIndex}`}
                                className="d-flex align-items-center mb-2"
                              >
                                <Form.Label
                                  style={{
                                    paddingTop: "1%",
                                    paddingRight: "1%",
                                  }}
                                >{`Row  ${correctIndex + 1}  :`}</Form.Label>
                                <div className="flex-grow-1">
                                  <Form.Control
                                    style={{ width: "20%" }}
                                    type="text"
                                    placeholder={`Row ${correctIndex + 1}`}
                                  />
                                </div>
                              </div>
                            )
                          )}
                          <span
                            onClick={() =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: [
                                        ...q.data,
                                        {
                                          _id: generateRandomId(),
                                          content: "",
                                          ...Array(numberOfCorrectValues)
                                            .fill("")
                                            .reduce(
                                              (acc, _, index) => ({
                                                ...acc,
                                                [`correct${index}`]: "false",
                                              }),
                                              {}
                                            ),
                                        },
                                      ],
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          >
                            <Plus size="18px" className="dropdown-item-icon" />{" "}
                            Add another Answer
                          </span>
                        </Form.Group>
                      </>
                    )}

                    {/* Multiple-choice grid QUESTION */}
                    {selectedType === "multi-choise-grid" && (
                      <>
                        <Form.Group className="mb-3" style={{ width: "87%" }}>
                          <Form.Label>Multi Choise Grid</Form.Label>
                          <span>
                            <div
                              style={{
                                cursor: "pointer",
                                marginLeft: "91%",
                                border: "1px solid #58A55C",
                                color: "white",
                                backgroundColor: "#58A55C",
                              }}
                              onClick={handleAddRow}
                            >
                              <Plus
                                color="white"
                                size="18px"
                                className="dropdown-item-icon"
                              />{" "}
                              Add row
                            </div>
                          </span>
                          {Array.isArray(q.data) &&
                            q.data.map((choice, choiceIndex) => (
                              <div
                                key={choice._id}
                                className="d-flex align-items-center mb-2"
                              >
                                <div className="flex-grow-1">
                                  <Form.Control
                                    type="text"
                                    placeholder={`Choice ${choiceIndex + 1}`}
                                    value={choice?.content}
                                    onChange={(e) =>
                                      handleOnchange({
                                        ...quiz,
                                        questions: quiz.questions.map(
                                          (element) => {
                                            if (element._id === q._id) {
                                              return {
                                                ...q,
                                                data: q.data.map((c, index) =>
                                                  index === choiceIndex
                                                    ? {
                                                        ...c,
                                                        content: e.target.value,
                                                      }
                                                    : c
                                                ),
                                              };
                                            }
                                            return element;
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>

                                <div
                                  className="ms-2"
                                  style={{ marginTop: "7px", display: "flex" }}
                                >
                                  {[...Array(numberOfCorrect).keys()].map(
                                    (correctIndex) => (
                                      <Form.Check
                                        style={{ marginLeft: "15px" }}
                                        key={correctIndex}
                                        type="radio"
                                        id={`q_multi_choice_${q._id}_${choice._id}_${correctIndex}`}
                                        label=""
                                        checked={
                                          choice[`correct${correctIndex}`] ===
                                          "true"
                                        }
                                        onChange={(e) =>
                                          handleOnchange({
                                            ...quiz,
                                            questions: quiz.questions.map(
                                              (element) => {
                                                if (element._id === q._id) {
                                                  return {
                                                    ...q,
                                                    data: q.data.map(
                                                      (c, index) =>
                                                        index === choiceIndex
                                                          ? {
                                                              ...c,
                                                              [`correct${correctIndex}`]:
                                                                e.target.checked
                                                                  ? "true"
                                                                  : "false",
                                                            }
                                                          : c
                                                    ),
                                                  };
                                                }
                                                return element;
                                              }
                                            ),
                                          })
                                        }
                                      />
                                    )
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "15%",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handleDeleteChoice(q._id, choice._id)
                                  }
                                >
                                  <Trash3
                                    size="15px"
                                    className="dropdown-item-icon"
                                    color="red"
                                  />
                                  Delete Choice
                                </div>
                              </div>
                            ))}
                          {[...Array(numberOfCorrect).keys()].map(
                            (correctIndex) => (
                              <div
                                key={`Row${correctIndex}`}
                                className="d-flex align-items-center mb-2"
                              >
                                <Form.Label
                                  style={{
                                    paddingTop: "1%",
                                    paddingRight: "1%",
                                  }}
                                >{`Row  ${correctIndex + 1}  :`}</Form.Label>
                                <div className="flex-grow-1">
                                  <Form.Control
                                    style={{ width: "20%" }}
                                    type="text"
                                    placeholder={`Row ${correctIndex + 1}`}
                                  />
                                </div>
                              </div>
                            )
                          )}
                          <span
                            onClick={() =>
                              handleOnchange({
                                ...quiz,
                                questions: quiz.questions.map((element) => {
                                  if (element._id === q._id) {
                                    return {
                                      ...q,
                                      data: [
                                        ...q.data,
                                        {
                                          _id: generateRandomId(),
                                          content: "",
                                          ...Array(numberOfCorrect)
                                            .fill("")
                                            .reduce(
                                              (acc, _, index) => ({
                                                ...acc,
                                                [`correct${index}`]: "false",
                                              }),
                                              {}
                                            ),
                                        },
                                      ],
                                    };
                                  }
                                  return element;
                                }),
                              })
                            }
                          >
                            <Plus size="18px" className="dropdown-item-icon" />{" "}
                            Add another Answer
                          </span>
                        </Form.Group>
                      </>
                    )}

                    <hr
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                  </Fragment>
                ))}

                <Form.Group className="mb-3">
                  <Form.Label>Add Question</Form.Label>
                  <Row>
                    <Col xs={4}>
                      <Form.Select
                        aria-label="Default select example"
                        id="type"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <option value="">Select Type</option>

                        {/* <option value="short_answer">Short answer</option> */}
                        {/* <option value="essay">Paragraph</option> */}
                        {/* <option value="checkbox">Checkbox</option> */}
                        <option value="multi-choice">Multiple choice</option>
                        <option value="true-false">True/false</option>
                        {/* <option value="linear-scale">Linear Scale</option> */}
                        {/* <option value="tick-box-grid">Tick Box Grid</option> */}
                        {/* <option value="multi-choise-grid">Multi Choise Grid</option> */}
                      </Form.Select>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          if (selectedType) {
                            const newQuestion = {
                              title: "",
                              category: "",
                              desc: "",
                              _id: generateRandomId(),
                              mark: 0,
                              type: selectedType,
                              data:
                                selectedType === "true-false"
                                  ? { correct: "false" }
                                  : selectedType === "multi-choice"
                                  ? [
                                      { _id: 1, content: "", correct: "false" },
                                      { _id: 2, content: "", correct: "false" },
                                      { _id: 3, content: "", correct: "false" },
                                      { _id: 4, content: "", correct: "false" },
                                    ]
                                  : selectedType === "checkbox"
                                  ? [
                                      { _id: 1, content: "", correct: "false" },
                                      { _id: 2, content: "", correct: "false" },
                                      { _id: 3, content: "", correct: "false" },
                                      { _id: 4, content: "", correct: "false" },
                                    ]
                                  : selectedType === "linear-scale"
                                  ? [
                                      {
                                        startValue: 0,
                                        endValue: 5,
                                        label1: "",
                                        label2: "",
                                      },
                                    ]
                                  : selectedType === "tick-box-grid"
                                  ? [
                                      {
                                        _id: 1,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 2,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 3,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 4,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                    ]
                                  : selectedType === "multi-choise-grid"
                                  ? [
                                      {
                                        _id: 1,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 2,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 3,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                      {
                                        _id: 4,
                                        content: "",
                                        correct0: "false",
                                        correct1: "false",
                                      },
                                    ]
                                  : "",
                            };

                            handleOnchange({
                              ...quiz,
                              questions: [...quiz?.questions, newQuestion],
                            });

                            setSelectedType("");
                          }
                        }}
                        disabled={loading}
                      >
                        <Plus size="18px" className="dropdown-item-icon" /> Add.
                      </span>
                    </Col>
                  </Row>
                </Form.Group>
                <div
                  className="mt-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={loading}
                    id="add-new-event-btn"
                    onClick={(e) => handleCreateQuiz(e)}
                  >
                    {quizId ? "Update Quiz" : "Add New Quiz"}
                  </Button>

                  {/* {quizId && <QuizToPDFGenerator quiz={quiz} />} */}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CreateQuiz;
