import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Image,
  Modal,
  Table,
} from "react-bootstrap";
import { Trash, MoreVertical, Edit, Eye } from "react-feather";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import AddNewPack from "./AddNewPack";
import {
  deletePackAction,
  fetchAllPacksAction,
  fetchPacksBySchoolAction,
  getTemplatesAction,
} from "actions/packs";
import TemplateCard from "./pack_details/TemplateCard";


const AllPacks = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { user } = useSelector((state) => state.auth);
  const [packId, setPackId] = useState(null);
  const [showPack, setShowPack] = useState(false);
  const [show, setShow] = useState(false);
  const { templates } = useSelector((state) => state.templates);
  const dispatch = useDispatch();
  const { packs } = useSelector((state) => state.packs);
  useEffect(() => {
    if (packId) setShowPack(true);
  }, [packId]);

  useEffect(() => {
    // dispatch(fetchAllPacksAction());
    dispatch(fetchPacksBySchoolAction(user.school_id));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTemplatesAction());
  }, [dispatch]);
  const handleDeletePack = (id) => {
    setPackToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeletePack = () => {
    if (packToDelete && confirmationMessage === "DELETE PACK") {
      dispatch(deletePackAction(packToDelete));
      setPackToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  useEffect(() => {
    if (packId) setShowPack(true);
  }, [packId]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [packToDelete, setPackToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));
  const ActionMenu = ({ id, setPackId }) => {
    return (
      <Dropdown style={{ zIndex: 1000 }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical
            size="15px"
            className="text-secondary"
            style={{ marginTop: "50px" }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <Link to={"/books-management/pack_details/" + id}>
              <Eye size="15px" className="dropdown-item-icon" />
              Pack Details
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              setPackId(id);
            }}
          >
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          {userData?.role == "admin" && (
            <Dropdown.Item eventKey="3" onClick={() => handleDeletePack(id)}>
              {" "}
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeletePack}
                disabled={confirmationMessage !== "DELETE PACK"}
              />{" "}
              Remove
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "id" },
      {
        accessor: "title",
        Header: "Packs",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div style={{ marginBottom: "15px" }}>
                <Link to={"/books-management/pack_details/" + row.original.id}>
                  <Image
                    src={row.original.img}
                    alt=""
                    className="img-4by3-lg rounded"
                    style={{ height: "160px", width: "110px" }}
                  />
                </Link>
              </div>
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <Link to={"/books-management/pack_details/" + row.original.id}>
                  <h4 className="text-secondary">{value}</h4>
                </Link>
              </div>
            </div>
          );
        },
      },
      // { accessor: 'title', Header: 'CATEGORY' },
      {
        accessor: "book_number",
        Header: "Book numbers",
        Cell: ({ value }) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5 className="text-secondary">{value}</h5>
            </div>
          );
        },
      },

      {
        accessor: "level",
        Header: "Level",
        Cell: ({ value }) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5 className="text-secondary">{value}</h5>
            </div>
          );
        },
      },
      {
        accessor: "age",
        Header: "Age",
        Cell: ({ value }) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5 className="text-secondary">{value}</h5>
            </div>
          );
        },
      },
      {
        accessor: "codes",
        Header: "Code Number",
        Cell: ({ value }) => {
          const isValueLowerThan20 = value < 20;

          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5
                className={`text-${
                  isValueLowerThan20 ? "danger" : "secondary"
                }`}
              >
                {value}
              </h5>
            </div>
          );
        },
      },
      {
        accessor: "price",
        Header: "Price",
        Cell: ({ value }) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5 className="text-secondary">{value}</h5>
            </div>
          );
        },
      },
      {
        accessor: "discount",
        Header: "Discount",
        Cell: ({ value }) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <h5 className="text-secondary">{value}</h5>
            </div>
          );
        },
      },
      {
        accessor: "action",
        Header: "",
        Cell: (row) => {
          return <ActionMenu id={row.row.original.id} setPackId={setPackId} />;
        },
      },
    ],
    []
  );

  const data = useMemo(() => packs, [packs]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Packs</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Books management</Breadcrumb.Item>
                <Breadcrumb.Item active>Packs</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Col className="d-flex justify-content-end">
              <div>
                <AddNewPack
                  showPack={showPack}
                  setShowPack={setShowPack}
                  setPackId={setPackId}
                  packId={packId}
                />
              </div>

              <div style={{ marginLeft: "20px" }}>
                <Button onClick={() => setShow(true)}>Import Template</Button>
              </div>
            </Col>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search Pack"
                    />
                  </Col>
                </Row>
              </div>

              <div className="table-responsive ">
                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete this pack?</p>
                  <h4 className="text-secondary">
                    you must write "DELETE PACK" to confirm the deletion
                  </h4>
                  <input
                    className="form-control"
                    rows="3"
                    placeholder="Enter confirmation message"
                    value={confirmationMessage}
                    onChange={(e) => setConfirmationMessage(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={
                      confirmationMessage !== "DELETE PACK"
                        ? "secondary"
                        : "danger"
                    }
                    disabled={confirmationMessage !== "DELETE PACK"}
                    onClick={confirmDeletePack}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {templates.map((item) => (
              <Col lg={3} md={6} sm={12} key={item.id}>
                <TemplateCard key={item.id} item={item} />
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </Fragment>
  );
};

export default AllPacks;
