import {
  addQuizToSession,
  assignQuizToUser,
  createNotification,
  createQuiz,
  deleteQuiz,
  deleteQuizFromSession,
  fetchAllQuizs,
  fetchOneQuiz,
  getEssayQuestion,
  getQuestion,
  getQuiz,
  getQuizAnswer,
  getQuizByToken,
  getQuizsInSession,
  getReaderInSession,
  getResult,
  importQuiz,
  updateQuiz,
  validationEssay,
} from "api";
import { toast } from "react-toastify";
import {
  ADD__QUIZ,
  ADD__QUIZS_TO_SESSION,
  CLEAR__QUIZ,
  CREATE_QUIZ,
  DELETE_QUIZ,
  DELETE__QUIZS_FROM_SESSION,
  GET_ALL_QUIZS,
  GET_ESSAY_QUESTIONS,
  GET_ONE_QUIZ,
  GET_RESULT_FOR_EACH_READER,
  GET_RESULT_IN_SESSION,
  GET__QUIZS_IN_SESSION,
  LOADING,
  UPDATE_QUIZ,
  VALIDATE_ESSAY_QUESTIONS,
} from "./constants";

export const fetchAllQuizsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchAllQuizs();
    dispatch({ type: GET_ALL_QUIZS, payload: data.quizs });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const fetchOneQuizAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchOneQuiz(id);
    dispatch({ type: GET_ONE_QUIZ, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const deleteQuizAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteQuiz(id);

    dispatch({ type: DELETE_QUIZ, payload: id });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const importQuizAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await importQuiz(formData);

    // const { data } = await fetchAllQuizs();
    // dispatch({ type: GET_ALL_QUIZS, payload: data });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const assignQuizToUserAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await assignQuizToUser(formData);
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const addQuizToSessionAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await addQuizToSession(formData);
      const id = data.quiz_session.quiz_token;
      const { data: quiz } = await fetchOneQuiz(id);
      toast.success(data.message, {
        autoClose: 1000,
      });
      const noti = {
        title: "New Quiz Added",
        desc: "Complete your assigned quiz assessments",
        link: `https://www.iread.tn/student/student-books/`,
        cat: "65ce32accc85c73cd2f5e723",
        target: formData.session_id,
      };
      const { data: userNoti } = await createNotification(noti);

      dispatch({ type: ADD__QUIZS_TO_SESSION, payload: quiz });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const dleteQuizFromSessionAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await deleteQuizFromSession(formData);
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      dispatch({
        type: DELETE__QUIZS_FROM_SESSION,
        payload: formData.quiz_token,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const getQuizInSessionAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuizsInSession(formData);
    if (data.quizes.length > 0) {
      const quizData = await Promise.all(
        data.quizes.map(async (e) => {
          const id = e.quiz_token;
          const { data: quiz } = await fetchOneQuiz(id);

          if (quiz) {
            return quiz;
          }
        })
      );
      dispatch({ type: GET__QUIZS_IN_SESSION, payload: quizData });
    } else {
      dispatch({ type: GET__QUIZS_IN_SESSION, payload: [] });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const getResultInSessionAction =
  (session_id, user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getQuizsInSession({ session_id });
      if (data.quizes.length > 0) {
        const quizData = await Promise.all(
          data.quizes.map(async (e) => {
            const id = e.quiz_token;
            const { data: quiz } = await getQuiz(id);
            if (quiz) {
              try {
                const result = await getQuizAnswer({
                  quiz: quiz._id,
                  user: user_id,
                });

                if (
                  result.data.score !== undefined &&
                  result.data.score !== null
                ) {
                  return {
                    ...quiz,
                    percentage: Math.round(result.data.percentage),
                    maxScore: result?.data?.max_score,
                    userScore: result?.data?.score,
                    success: result?.data?.success,
                    completed: result?.data?.completed,
                    answers: result?.data?.answers,
                  };
                }
              } catch (error) {
                return { ...quiz, final_results: "incomplete" };
              }
            }
          })
        );

        dispatch({ type: GET_RESULT_IN_SESSION, payload: quizData });
      } else {
        dispatch({ type: GET_RESULT_IN_SESSION, payload: [] });
      }

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const fetchResultForEachReader = (session_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data: readers } = await getReaderInSession(session_id);
    const sessionData = await Promise.all(
      readers.session_follow_requests.map(async (reader) => {
        const { data } = await getQuizsInSession({ session_id });
        const xr = await Promise.all(
          data.quizes.map(async (e) => {
            const id = e.quiz_token;
            const { data: quiz } = await getQuiz(id);
            if (quiz) {
              try {
                const result = await getQuizAnswer({
                  quiz: quiz._id,
                  user: reader.quiz_id,
                });
                if (
                  result.data.score !== undefined &&
                  result.data.score !== null
                ) {
                  return {
                    title: quiz.title,
                    final_results: Math.round(result.data.percentage),
                    success: result.data.success,
                  };
                }
              } catch (error) {
                return {
                  title: quiz.title,
                  final_results: "incomplete",
                  success: false,
                };
                // if (error.response.data.message == "Quiz not completed") {
                //   return {
                //     title: quiz.title,
                //     final_results: "incomplete",
                //     success: false,
                //   };
                // } else {
                //   return {
                //     title: quiz.title,
                //     final_results: "incorrected",
                //     success: false,
                //   };
                // }
              }
            }
          })
        );

        const finalData = xr.reduce((accumulator, e) => {
          accumulator[e.title] = {
            precent: e.final_results,
            success: e.success,
          };
          return accumulator;
        }, {});
        return {
          Name: reader.username,
          quiz_id: reader.quiz_id,
          Presence: reader.presence,
          ...finalData,
        };
      })
    );
    dispatch({ type: GET_RESULT_FOR_EACH_READER, payload: sessionData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: GET_RESULT_FOR_EACH_READER, payload: [] });
    console.error(error);
    if (error?.response?.data?.message) {
      console.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchEssayQuestionAction =
  (token, user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getEssayQuestion(token, user_id);
      dispatch({ type: GET_ESSAY_QUESTIONS, payload: data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const validationEssayAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await validationEssay(fromData);
      dispatch({ type: VALIDATE_ESSAY_QUESTIONS, payload: data });
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      // window.location.href = "/teacher-dashboard/sessions";
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const addQuiz = (quiz) => (dispatch) => {
  dispatch({ type: ADD__QUIZ, payload: quiz });
};

export const createQuizAction = (fromData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await createQuiz(fromData);
    dispatch({ type: CREATE_QUIZ, payload: data });
    dispatch({ type: CLEAR__QUIZ });
    navigate("/orders/quiz-list");

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const updateQuizAction =
  (fromData, setQuizId, navigate) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await updateQuiz(fromData);
      dispatch({ type: UPDATE_QUIZ, payload: data });
      setQuizId();
      dispatch({ type: CLEAR__QUIZ });
      navigate("/orders/quiz-list");

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
