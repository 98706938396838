import { getJsonListAction } from "actions/adaptativeLearning";
import { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AutocompleteStory = ({ selectedOption, setSelectedOption }) => {
    const dispatch = useDispatch();
    const { stories } = useSelector((state) => state?.words);
  
    useEffect(() => {
      dispatch(getJsonListAction(935));
    }, [dispatch]);
  
    const handleSelection = (selected) => {
      setSelectedOption(selected);
    };
  
    const options = [...stories.map((item) => item)];
  
    return (
      <div className="mb-3">
        <Typeahead
          id="autocomplete-example"
          labelKey="title"
          options={options}
          placeholder="Type to search..."
          selected={selectedOption || []}
          onChange={handleSelection}
        />
      </div>
    );
  };
  
  export default AutocompleteStory;
  