// import node module libraries
import { Fragment, useEffect, useRef, useState } from "react";
import {
  Form,

  Button,
  Offcanvas,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// import custom components

import {
  fetchPackAction,
  editPackAction,
  createPackAction,
} from "actions/packs";

const AddNewPack = ({ packId, setPackId, showPack, setShowPack }) => {
  const { pack: packEdited } = useSelector((state) => state.packs);
  const { loading, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [faqs, setFaqs] = useState([{ title: "", desc: "" }]);
  const addFAQ = () => {
    setFaqs([...faqs, { title: "", desc: "" }]);
  };
  const updateFAQ = (index, field, value) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index][field] = value;
    setFaqs(updatedFaqs);
  };
  const [pack, setPack] = useState({
    title: "",
    level: "",
    age: "kid",
    price: 0,
    discount: 0,
    desc: "",
    duration: "",
    faq: "",
    public: false,
  });
  const [img, setImg] = useState(
    "https://cdn-icons-png.flaticon.com/512/46/46862.png"
  );

  //cloudinary
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;

    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dtwkikmuy",
        uploadPreset: "wuraurta",
      },
      (error, result) => {
        if (result.info.secure_url) {
          setImg(result.info.secure_url);
        }
      }
    );
  }, []);

  const handleAddPack = async (e) => {
    if (packId) {
      dispatch(
        editPackAction(
          { ...pack, img: img, faq: faqs },
          handleClose,
          setPackId,
          setImg,
          setFaqs
        )
      );
    } else {
      dispatch(
        createPackAction(
          { ...pack, img: img, faq: faqs, school_id: user.school_id },
          handleClose,
          setFaqs,
          setImg
        )
      );
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (packId) {
      dispatch(fetchPackAction(packId));
    }
  }, [dispatch, packId]);
  useEffect(() => {
    if (packId) {
      setPack({
        id: packEdited.id,
        title: packEdited.title,
        age: packEdited.age,
        price: packEdited.price,
        discount: packEdited.discount,
        desc: packEdited.desc,
        level: packEdited.level,
        diration: packEdited.duration,
        public: packEdited.public || false,
      });
      setImg(packEdited.img);
      setFaqs(packEdited.faq);
    }
  }, [packEdited]);
  const handleClose = () => {
    setPack({
      title: "",
      level: "",
      age: "",
      price: 0,
      discount: 0,
      desc: "",
      level: "",
      duration: "",
      faq: "",
      public: false,
    });
    setShowPack(false);
    setPackId(null);
  };
  const handleShow = () => setShowPack(true);

  return (
    <Fragment>
      {user?.school == "IRead" && (
        <Button variant="primary" onClick={handleShow}>
          Add New Pack
        </Button>
      )}

      <Offcanvas show={showPack} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {packId ? "Update Pack" : "Add New Pack"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Image  */}
            {user?.school == "IRead" && (
              <Form.Group className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={img}
                    alt=""
                    className="me-3"
                    style={{
                      width: "130px",
                      height: "130px",
                    }}
                  />
                  <Button
                    style={{
                      marginTop: "21px",
                      width: "100%",
                    }}
                    variant="primary"
                    size="sm"
                    onClick={() => widgetRef.current.open()}
                    id="img"
                  >
                    {packId ? "Update Image" : "Upload New Image"}
                  </Button>
                </div>
              </Form.Group>
            )}
            {/* Title  */}
            {user?.school == "IRead" && (
              <Form.Group className="mb-3">
                <Form.Label>Pack Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pack Title"
                  id="title"
                  required
                  value={pack.title}
                  onChange={(e) => setPack({ ...pack, title: e.target.value })}
                />
              </Form.Group>
            )}
            {/* level  */}
            <Form.Group className="mb-3">
              <Form.Label>level</Form.Label>
              <Form.Control
                type="text"
                placeholder="level"
                id="level"
                required
                value={pack.level}
                onChange={(e) => setPack({ ...pack, level: e.target.value })}
              />
            </Form.Group>
            {/* age  */}
            {user?.school == "IRead" && (
              <Form.Group className="mb-3">
                <Form.Label>Age</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  id="age"
                  value={pack.age}
                  onChange={(e) => setPack({ ...pack, age: e.target.value })}
                >
                  <option value="kid">KIDS</option>
                  <option value="teenager">TEENAGERS</option>
                  <option value="adult">ADULTS</option>
                </Form.Select>
              </Form.Group>
            )}
            {/* price  */}
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Price Here"
                id="price"
                value={pack.price}
                onChange={(e) => setPack({ ...pack, price: e.target.value })}
              />
            </Form.Group>
            {/* discount  */}
            <Form.Group className="mb-3">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Discount Here"
                id="discount"
                value={pack.discount}
                onChange={(e) => setPack({ ...pack, discount: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="number"
                placeholder="Duration Here"
                id="duration"
                value={pack.duration}
                onChange={(e) => setPack({ ...pack, duration: e.target.value })}
              />
            </Form.Group>

            {/* description */}
            {user?.school == "IRead" && (
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="6"
                  placeholder="Description Here"
                  id="desc"
                  value={pack.desc}
                  onChange={(e) => setPack({ ...pack, desc: e.target.value })}
                />
              </Form.Group>
            )}
            {user?.school == "IRead" && (
              <Form.Group className="mb-3">
                <Form.Label>Public</Form.Label>

                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Check this switch"
                  checked={pack.public}
                  onChange={(e) =>
                    setPack({ ...pack, public: e.target.checked })
                  }
                />
              </Form.Group>
            )}

            {user?.school == "IRead" && (
              <Fragment>
                {/* FAQ */}

                <Form.Group className="mb-3">
                  <Form.Label>FAQ</Form.Label>
                  {faqs?.map((faq, index) => (
                    <div key={index}>
                      <Form.Control
                        type="text"
                        placeholder="Title Here"
                        value={faq.title}
                        onChange={(e) =>
                          updateFAQ(index, "title", e.target.value)
                        }
                      />
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description Here"
                        value={faq.desc}
                        onChange={(e) =>
                          updateFAQ(index, "desc", e.target.value)
                        }
                      />
                    </div>
                  ))}
                </Form.Group>

                <Button variant="danger" onClick={addFAQ} className="float-end">
                  + FAQ
                </Button>
                {/* ... Le reste de votre code ... */}
              </Fragment>
            )}
            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddPack(e)}
              >
                {packId ? "Update Pack" : "Add New Pack"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddNewPack;
