import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategoriesAction } from "actions/categoryQuiz";
import { addQuiz } from "actions/quiz";

const AutocompleteCategory = ({ q }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState();
  const { quiz } = useSelector((state) => state.quizs);
  const { quizCategories } = useSelector((state) => state.quizCategories);
  useEffect(() => {
    dispatch(fetchAllCategoriesAction());
  }, [dispatch]);

  const handleOnchange = (data) => {
    dispatch(addQuiz(data));
  };
  const handleSelection = (selected) => {
    if (selected.length > 0) {
      handleOnchange({
        ...quiz,
        questions: quiz.questions.map((element) => {
          if (element._id === q._id) {
            return { ...element, category: selected[0].name };
          }
          return element;
        }),
      });
    }
  };

  console.log(q);
  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="name"
        options={quizCategories || []}
        placeholder="Type to search Category..."
        selected={[q?.category]}
        onChange={handleSelection}
        onInputChange={(e) =>
          handleOnchange({
            ...quiz,
            questions: quiz.questions.map((element) => {
              if (element._id === q._id) {
                return { ...element, category: e };
              }
              return element;
            }),
          })
        }
      />
    </div>
  );
};

export default AutocompleteCategory;
