import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const WordDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");
  const { wordDatas } = location?.state || {};
  const [wordData, setWordData] = useState({
    lemma: "",
    pos: "",
    level: "",
    word: "",
    tag: "",
  });
  const [verbData, setVerbData] = useState({
    lemma: "",
    pos: "",
    level: "",
    tense: "",
    word: "",
    tag: "",
  });
  useEffect(() => {
    if (wordDatas) {
      if (wordDatas.tense) {
        setVerbData(wordDatas);
      } else {
        setWordData(wordDatas);
      }
    }
  }, [wordDatas]);
  const handelClearData = () => {
    setVerbData({
      lemma: "",
      pos: "",
      level: "",
      tense: "",
      word: "",
      tag: "",
    });
    setWordData({
      lemma: "",
      pos: "",
      level: "",
      word: "",
      tag: "",
    });
  };
  const handleUpdateWord = (e) => {
    e.preventDefault();
    handelClearData();
    navigate("/teacher/add-story/" + title);
  };

  const handleUpdateVerb = (e) => {
    e.preventDefault();
    handelClearData();
    navigate("/teacher/add-story/" + title);
  };
  return (
    <div>
      <h1 style={{ paddingBottom: "10px" }}>Word Details</h1>
      <hr style={{ margin: "20px 0" }} />
      {wordDatas && wordDatas.tense ? (
        <Form
          style={{ width: "50%", marginLeft: "20px", paddingBottom: "30px" }}
        >
          {/* word  */}
          <Form.Group className="mb-3">
            <Form.Label>Word</Form.Label>
            <Form.Control
              type="text"
              placeholder="word"
              id="word"
              required
              value={verbData.word}
              onChange={(e) =>
                setVerbData({ ...verbData, word: e.target.value })
              }
            />
          </Form.Group>
          {/* pos  */}
          <Form.Group className="mb-3">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              placeholder="pos"
              id="pos"
              value={verbData.pos}
              required
              onChange={(e) =>
                setVerbData({ ...verbData, pos: e.target.value })
              }
            />
          </Form.Group>
          {/* lemma  */}
          <Form.Group className="mb-3">
            <Form.Label>Lemma</Form.Label>
            <Form.Control
              type="text"
              placeholder="lemma"
              id="lemma"
              required
              value={verbData.lemma}
              onChange={(e) =>
                setVerbData({ ...verbData, lemma: e.target.value })
              }
            />
          </Form.Group>
          {/* Tag  */}
          <Form.Group className="mb-3">
            <Form.Label>Tag</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tag"
              id="tag"
              required
              value={verbData.tag}
              onChange={(e) =>
                setVerbData({ ...verbData, tag: e.target.value })
              }
            />
          </Form.Group>
          {/* tense  */}
          <Form.Group className="mb-3">
            <Form.Label>Tense</Form.Label>
            <Form.Select
              id="tense"
              value={verbData.tense}
              required
              onChange={(e) =>
                setVerbData({ ...verbData, tense: e.target.value })
              }
            >
              <option value="">Select Tense</option>
              <option value="Simple Present">Simple Present</option>
              <option value="Present Continuous">Present Continuous</option>
              <option value="Present Perfect">Present Perfect</option>
              <option value="Present Perfect Continuous">
                Present Perfect Continuous
              </option>
              <option value="Simple Past">Simple Past</option>
              <option value="Past Continuous">Past Continuous</option>
              <option value="Past Perfect">Past Perfect</option>
              <option value="Past Perfect Continuous">
                Past Perfect Continuous
              </option>
              <option value="Simple Future">Simple Future</option>
              <option value="Future Continuous">Future Continuous</option>
              <option value="Future Perfect">Future Perfect</option>
              <option value="Future Perfect Continuous">
                Future Perfect Continuous
              </option>
            </Form.Select>
          </Form.Group>
          {/* level  */}
          <Form.Group className="mb-3">
            <Form.Label>Level</Form.Label>
            <Form.Select
              id="level"
              value={verbData.level}
              required
              onChange={(e) =>
                setVerbData({ ...verbData, level: e.target.value })
              }
            >
              <option value="">Select Level</option>
              <option value="A1">A1</option>
              <option value="A2">A2</option>
              <option value="B1">B1</option>
              <option value="B2">B2</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
            </Form.Select>
          </Form.Group>

          <div className="mt-3">
            <Button
              type="submit"
              variant="primary"
              id="add-new-event-btn"
              // disabled={loading}
              onClick={(e) => handleUpdateVerb(e)}
            >
              update
            </Button>
          </div>
        </Form>
      ) : (
        <Form
          style={{ width: "50%", marginLeft: "20px", paddingBottom: "30px" }}
        >
          {/* word  */}
          <Form.Group className="mb-3">
            <Form.Label>Word</Form.Label>
            <Form.Control
              type="text"
              placeholder="word"
              id="word"
              required
              value={wordData.word}
              onChange={(e) =>
                setWordData({ ...wordData, word: e.target.value })
              }
            />
          </Form.Group>
          {/* pos  */}
          <Form.Group className="mb-3">
            <Form.Label>pos</Form.Label>
            <Form.Control
              type="text"
              placeholder="pos"
              id="pos"
              value={wordData.pos}
              required
              onChange={(e) =>
                setWordData({ ...wordData, pos: e.target.value })
              }
            />
          </Form.Group>
          {/* lemma  */}
          <Form.Group className="mb-3">
            <Form.Label>Head Word</Form.Label>
            <Form.Control
              type="text"
              placeholder="lemma"
              id="lemma"
              required
              value={wordData.lemma}
              onChange={(e) =>
                setWordData({ ...wordData, lemma: e.target.value })
              }
            />
          </Form.Group>
          {/* Tag  */}
          <Form.Group className="mb-3">
            <Form.Label>Tag</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tag"
              id="tag"
              required
              value={wordData.tag}
              onChange={(e) =>
                setWordData({ ...wordData, tag: e.target.value })
              }
            />
          </Form.Group>

          {/* level  */}
          <Form.Group className="mb-3">
            <Form.Label>Level</Form.Label>
            <Form.Select
              id="level"
              value={wordData.level}
              required
              onChange={(e) =>
                setWordData({ ...wordData, level: e.target.value })
              }
            >
              <option value="">Select Level</option>
              <option value="A1">A1</option>
              <option value="A2">A2</option>
              <option value="B1">B1</option>
              <option value="B2">B2</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
            </Form.Select>
          </Form.Group>

          <div className="mt-3">
            <Button
              type="submit"
              variant="primary"
              id="add-new-event-btn"
              // disabled={loading}
              onClick={(e) => handleUpdateWord(e)}
            >
              Update
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default WordDetails;
