import {
  getAllFollowedSessions,
  approveFollowedSession,
  deleteFollowedSession,
  rejectFollowedSession,
  createNotification,
  createNotificationUser,
  addUserToSession,
  removeUserFromSession,
  createFollowedSession,
} from "api";
import {
  GET_ALL_FOLLOWED_SESSIONS,
  APPROVE_FOLLOWED_SESSION,
  DELETE_FOLLOWED_SESSION,
  REJECT_FOLLOWED_SESSION,
  LOADING,
} from "./constants";
import { toast } from "react-toastify";
export const fetchAllFollowedSessionsAction =
  (fromData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getAllFollowedSessions(fromData);

      dispatch({
        type: GET_ALL_FOLLOWED_SESSIONS,
        payload: data.session_follow_requests,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const ApproveFollowedSessionAction =
  (fromData, sessionTitle) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await approveFollowedSession(fromData);
      const noti = {
        title: sessionTitle,
        desc: "Your session is now approved.",
        link: `https://www.iread.tn/student/student-books/`,
        cat: "657180fe97959a4a3ee8d1d8",
        target: fromData.user_id,
      };
      const { data: userNoti } = await createNotification(noti);
      await createNotificationUser({
        user_id: userNoti.target,
        notification_id: userNoti._id,
      });
      dispatch({ type: APPROVE_FOLLOWED_SESSION, payload: fromData });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const deleteFollowedSessionRequestAction =
  (fromData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await deleteFollowedSession(fromData);

      dispatch({ type: DELETE_FOLLOWED_SESSION, payload: fromData });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const RejectFollowedSessionAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    await rejectFollowedSession(fromData);

    dispatch({ type: REJECT_FOLLOWED_SESSION, payload: fromData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const addUserToSessionAction =
  (fromData, setShowOffcanvas) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await addUserToSession(fromData);

      dispatch({ type: LOADING, payload: false });
      // toast.success(data.message, {
      //   autoClose: 1000,
      // });
      setShowOffcanvas(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
      }

      dispatch({ type: LOADING, payload: false });
    }
  };
export const removeUserFromSessionAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await removeUserFromSession(fromData);

    dispatch({ type: LOADING, payload: false });
    // toast.success(data.message, {
    //   autoClose: 1000,
    // });

    window.location.reload();
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
      });
    }

    dispatch({ type: LOADING, payload: false });
  }
};

export const createFollowSessionAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createFollowedSession(fromData);
      handleClose();
      // dispatch({ type: LOADING, payload: data.follow_session });
      dispatch({ type: LOADING, payload: false });
      toast.success(data.message, {
        autoClose: 1000,
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
      }

      dispatch({ type: LOADING, payload: false });
    }
  };
