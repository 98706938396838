import {
    getJsonListAction,
    splitStoryAction,
} from "actions/adaptativeLearning";
import { saveJson } from "api";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Modal, Button, ButtonGroup, Table } from "react-bootstrap";
import { Edit, Eye, Plus } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { useNavigate } from "react-router-dom";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
} from "react-table";
import AutocompleteStory from "./AutocompleteStory";

const ChooseStory = () => {
    const { stories } = useSelector((state) => state?.words);
    const [selectedOption, setSelectedOption] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const { user } = useSelector((state) => state?.auth);
   
    useEffect(() => {
        dispatch(getJsonListAction(user.id));
    }, [dispatch]);
    const handleCreateStory = async () => {
        await saveJson({ title, teacherId: user.id })
            .then((res) => {
                navigate("/teacher/add-story/" + selectedOption + "-by-" + user.id);
                console.log(res);
            })
            .catch((err) => console.log(err));
    };
    const handleNavigateToStory = async () => {
        const selectedTitle = selectedOption[0] || ''; 
            navigate("/teacher/add-story/" + encodeURIComponent(selectedTitle) + "-by-" + user.id);
         
      };

    const ActionMenu = () => {
        return (
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                    <MoreVertical size="15px" className="text-secondary" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Header>ACTION</Dropdown.Header>
                    <Dropdown.Item eventKey="1">
                        {" "}
                        <Send size="18px" className="dropdown-item-icon" /> Approved
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2">
                        {" "}
                        <Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3">
                        {" "}
                        <Trash size="18px" className="dropdown-item-icon" /> Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const columns = useMemo(() => [{ accessor: "title", Header: "Title" }], []);

    const data = useMemo(() => stories, [stories]);

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    pageSize: 10,
                    hiddenColumns: columns.map((column) => {
                        if (column.show === false) return column.accessor || column.id;
                        else return false;
                    }),
                },
            },
            useFilters,
            useGlobalFilter,
            usePagination,
            useRowSelect,
            (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    {
                        /* 	id: 'selection',
                                    Header: ({ getToggleAllRowsSelectedProps }) => (
                                        <Checkbox {...getToggleAllRowsSelectedProps()} />
                                    ),
                                    Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
                    },
                    ...columns,
                ]);
            }
        );
    return (
        <div>
        <Row>
            <Col lg={12} md={12} sm={12}>
                <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
                    <div className="mb-3 mb-lg-0">
                        <h1 className="mb-0 h2 fw-bold">My Compilation of Literary Creations</h1>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col lg={12} md={12} sm={12}>
                <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
                    <div>
                        <AutocompleteStory
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                        />
                        <button className="btn btn-primary" onClick={handleNavigateToStory}>
                            Get the story
                        </button>
                    </div>
                    <div className="text-end mb-4 pt-13">
            <button className="btn btn-success"  onClick={() => navigate("/teacher/create-story")}>
                Create New Story
            </button>
        </div>
                </div>
            </Col>
        </Row>
     
    </div>
    );
};

export default ChooseStory;
