import {
  getJsonListAction,
  splitStoryAction,
} from "actions/adaptativeLearning";
import { saveJson } from "api";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Modal, Button, ButtonGroup, Table } from "react-bootstrap";
import { Edit, Eye, Plus } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";

const CreateStory = () => {
  const { stories } = useSelector((state) => state?.words);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getJsonListAction(2));
  }, [dispatch]);

  const handleCreateStory = async () => {
    await saveJson({ title, teacherId: user.id })
      .then((res) => {
        navigate("/teacher/add-story/" + title + "-by-" + user.id);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <Send size="18px" className="dropdown-item-icon" /> Approved
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            {" "}
            <Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {" "}
            <Trash size="18px" className="dropdown-item-icon" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(() => [{ accessor: "title", Header: "Title" }], []);

  const data = useMemo(() => stories, [stories]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 10,
          hiddenColumns: columns.map((column) => {
            if (column.show === false) return column.accessor || column.id;
            else return false;
          }),
        },
      },
      useFilters,
      useGlobalFilter,
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            /* 	id: 'selection',
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<Checkbox {...getToggleAllRowsSelectedProps()} />
						),
						Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
          },
          ...columns,
        ]);
      }
    );
  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Create Story</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-control mb-3"
                placeholder="Title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></input>
              <button className="btn btn-primary" onClick={handleCreateStory}>
                Create
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="table-responsive ">
        <Table {...getTableProps()} className="text-nowrap">
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CreateStory;
