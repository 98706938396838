/* import { getAllBooksInPack, getAllPackDetails, getAllPacks } from "api";
import {
  GET_ALL_PAKCS,
  GET_BOOKS_IN_PACK,
  GET_PACK_DETAILS,
  LOADING,
} from "./constants";

export const fetchAllPacksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPacks();

    dispatch({ type: GET_ALL_PAKCS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchAllPackDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPackDetails(id);

    dispatch({ type: GET_PACK_DETAILS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchBooksInPackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllBooksInPack(id);

    dispatch({ type: GET_BOOKS_IN_PACK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
 */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllPacks,
  getPackById,
  editPack,
  deletePack,
  createPack,
  getAllBooksInPack,
  addBookInPack,
  deleteBookFromPack,
  generateCode,
  fetchCodes,
  updateCode,
  generateTemplate,
  importTemplate,
  getOneTemplate,
  getTemplates,
  getPacksByShcool,
} from "api";
import {
  GET_ALL_PACKS,
  GET_PACK,
  EDIT_PACK,
  DELETE_PACK,
  CREATE_PACK,
  GET_BOOKS_IN_PACK,
  DELETE_BOOKS_FROM_PACK,
  ADD_BOOKS_IN_PACK,
  LOADING,
  GENERATE_CODE,
  GET_CODES,
  EDIT_CODES,
  GET_ONE_TEMPLATE,
  GET_ALL_TEMPLATES,
} from "./constants";

export const fetchAllPacksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllPacks();
    if (data.packs) {
      dispatch({ type: GET_ALL_PACKS, payload: data });
    } else {
      dispatch({ type: GET_ALL_PACKS, payload: { packs: [] } });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchPackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getPackById(id);
    dispatch({ type: GET_PACK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchPacksBySchoolAction = (school) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getPacksByShcool(school);

    dispatch({ type: GET_ALL_PACKS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const editPackAction =
  (fromData, handleClose, setPackId, setImg, setFaqs) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await editPack(fromData);
      dispatch({ type: EDIT_PACK, payload: data?.pack });
      handleClose();
      setPackId(null);
      setImg("https://cdn-icons-png.flaticon.com/512/46/46862.png");
      setFaqs([{ title: "", desc: "" }]);
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deletePackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await deletePack(id);

    dispatch({ type: DELETE_PACK, payload: id });
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createPackAction =
  (fromData, handleClose, setFaqs, setImg) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createPack(fromData);

      dispatch({ type: CREATE_PACK, payload: data.pack });
      handleClose();
      setFaqs([{ title: "", desc: "" }]);
      setImg("https://cdn-icons-png.flaticon.com/512/46/46862.png");
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const fetchBooksInPackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllBooksInPack(id);

    dispatch({ type: GET_BOOKS_IN_PACK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const addBookInPackAction =
  (book_id, pack_id, handleClose, setSelectedOption) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await addBookInPack(book_id, pack_id);
      dispatch({ type: ADD_BOOKS_IN_PACK, payload: data.book });
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      setSelectedOption([]);
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteBookFromPackAction =
  (book_id, pack_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const data = await deleteBookFromPack(book_id, pack_id);

      dispatch({ type: DELETE_BOOKS_FROM_PACK, payload: book_id });
      toast.success(data.data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const generateCodeAction =
  (codeNumber, packId, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await generateCode(codeNumber, packId);
      dispatch({ type: GENERATE_CODE, payload: data });
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const fetchCodesAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchCodes(id);
    dispatch({ type: GET_CODES, payload: data.codes });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const updateCodeAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const fromData = { status: "pending" };
    const { data } = await updateCode(id, fromData);
    dispatch({ type: EDIT_CODES, payload: data });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const generateTemplateAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await generateTemplate(id);
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const addTemplateAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await importTemplate(id);
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getTemplateAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getOneTemplate(id);

    dispatch({ type: GET_ONE_TEMPLATE, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getTemplatesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getTemplates();
    dispatch({ type: GET_ALL_TEMPLATES, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
